<template>
  <v-container>
    <v-container v-if="allEvaluations.length">
      <v-card
        v-for="(client, index) in allEvaluations"
        :key="'client' + index"
        width="100%"
        elevation="0"
        class="pa-5"
      >
        <v-container
          class="d-flex pl-0 flex-column flex-sm-row justify-space-between"
        >
          <v-container class="d-flex pl-0">
            <div>
              <v-img
                :width="width"
                class="rounded-circle"
                :src="`${allEvaluations[index].clientImg}`"
                :lazy-src="`${allEvaluations[index].clientImg}`"
              ></v-img>
            </div>
            <div class="ml-6">
              <b>{{ allEvaluations[index].clientName }}</b>
              <br />
              <i>{{ allEvaluations[index].evaluationDate }}</i>
            </div>
          </v-container>
          <v-container class="d-flex flex-column align-end"
            ><div>
              <b class="mr-1">Recomendación Zaturna:</b>
              {{ allEvaluations[index].rating }}
            </div>

            <b v-if="allEvaluations[index].type === 'provider'"
              >Servicio: {{ allEvaluations[index].typeName }}</b
            >
          </v-container>
        </v-container>

        <div class="mt-2">
          <i
            >{{ allEvaluations[index].clientMsg }}
            <ModalEvaluation
              class="mt-2"
              :evaluation-info="allEvaluations[index]"
            />
          </i>
        </div>
        <v-divider class="mt-5"></v-divider>
      </v-card>
    </v-container>
    <v-container v-else>
      <v-card
        width="100%"
        elevation="2"
        class="rounded-lg pa-5 mb-5"
        align="center"
      >
        No se encontraron evaluaciones</v-card
      >
    </v-container></v-container
  >
</template>

<script>
import ModalEvaluation from "./ModalEvaluation.vue";

export default {
  name: "EvaluationCard",
  components: { ModalEvaluation },
  props: {
    allEvaluations: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    width: 63,
  }),

  computed: {},

  methods: {},
};
</script>

<style scoped></style>
