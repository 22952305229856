<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>

    <v-container class="pb-0 body-container">
      <v-container class="pb-0">
        <v-row>
          <v-col cols="12">
            <h1 class="mt-10">Evaluaciones</h1>
          </v-col>
          <v-col cols="12" sm="10">
            <v-card class="elevation-0">
              <v-tabs
                v-model="filterResult"
                background-color="white"
                show-arrows
              >
                <v-tabs-slider
                  color="primary"
                  class="text-decoration-none"
                ></v-tabs-slider>
                <v-tab @click="tabChange()"> Todas </v-tab>
                <v-tab @click="tabChange()"> Clientes </v-tab>
                <v-tab @click="tabChange()"> Proveedores </v-tab>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>

      <v-row>
        <v-col
          cols="12"
          md="5"
          class="pt-10 pl-md-16 d-flex justify-center justify-md-start"
        >
          <b>Promedio recomendación Zaturna:</b>
          {{ averageRecomendation }}
        </v-col>
        <v-col cols="1" md="2"></v-col>
        <v-col cols="10" md="5">
          <v-text-field
            v-model="nameFilter"
            align="end"
            outlined
            dense
            class="searchInput rounded-md pt-sm-2"
            color="primary"
            label="Nombre del cliente"
            placeholder="¿Cuál es el nombre del usuario?"
          >
            <template v-slot:append>
              <Button
                ref="searchInput"
                text="Buscar"
                class="mb-2"
                aria-label="Buscar evaluación por nombre del cliente"
                @event="inputTextConsole()"
              />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <Evaluation-card :all-evaluations="loadedEvaluations" />
      <v-container v-if="showMoreBtn === true" class="d-flex justify-center">
        <Button
          text="Ver más evaluaciones"
          outlined
          color="primary"
          aria-label="Ver más evaluaciones"
          @event="handleShowEvaluations()"
        />
      </v-container>
    </v-container>

    <Footer class="mt-12" />
  </v-container>
</template>

<script>
import Navbar from "@/components/Admin/Shared/Navbar.vue";
import EvaluationCard from "@/components/Admin/Evaluations/EvaluationCard.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/Shared/Footer.vue";

export default {
  components: {
    Navbar,
    EvaluationCard,
    Button,
    Footer,
  },
  data: () => ({
    nameFilter: "",
    averageRecomendation: 8,
    filterResult: 0,
    evaluationShow: 3,
    showMoreBtn: true,
    allEvaluations: [
      {
        id: 21,
        clientName: "Name McName",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 6,
        type: "provider",
        typeName: "Salones de Chihuahua2",
        serviceStar: 4,
        qualityStar: 5,
        overallStar: 4,
        recomendationAverage: 9,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 22,
        clientName: "Another name",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 5,
        type: "provider",
        typeName: "Salones de Chihuahua1",
        serviceStar: 5,
        qualityStar: 4,
        overallStar: 4,
        recomendationAverage: 10,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 23,
        clientName: "Panfilo Lopez",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 4,
        type: "client",

        serviceStar: 3,
        qualityStar: 1,
        overallStar: 3,
        recomendationAverage: 4,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 24,
        clientName: "Name McName",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 3,
        type: "provider",
        typeName: "Salones de Chihuahua4",
        serviceStar: 4,
        qualityStar: 5,
        overallStar: 4,
        recomendationAverage: 9,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 25,
        clientName: "Another name",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 2,
        type: "provider",
        typeName: "Salones de Chihuahua10",
        serviceStar: 5,
        qualityStar: 4,
        overallStar: 4,
        recomendationAverage: 10,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 26,
        clientName: "Panfilo Lopez",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 1,
        type: "client",

        serviceStar: 3,
        qualityStar: 1,
        overallStar: 3,
        recomendationAverage: 4,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
    ],
    clientEvaluations: [
      {
        id: 15,
        clientName: "Panfilo Lopez",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 4,
        type: "client",

        serviceStar: 3,
        qualityStar: 1,
        overallStar: 3,
        recomendationAverage: 4,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 16,
        clientName: "Panfilo Lopez",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 3,
        type: "client",

        serviceStar: 3,
        qualityStar: 1,
        overallStar: 3,
        recomendationAverage: 4,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 23,
        clientName: "Panfilo Lopez",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 2,
        type: "client",

        serviceStar: 3,
        qualityStar: 1,
        overallStar: 3,
        recomendationAverage: 4,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 26,
        clientName: "Panfilo Lopez",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 1,
        type: "client",

        serviceStar: 3,
        qualityStar: 1,
        overallStar: 3,
        recomendationAverage: 4,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
    ],
    providerEvaluations: [
      {
        id: 21,
        clientName: "Name McName",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 8,
        type: "provider",
        typeName: "Salones de Chihuahua1",
        serviceStar: 4,
        qualityStar: 5,
        overallStar: 4,
        recomendationAverage: 9,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 22,
        clientName: "Another name",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 9,
        type: "provider",
        typeName: "Salones de Chihuahua2",
        serviceStar: 5,
        qualityStar: 4,
        overallStar: 4,
        recomendationAverage: 10,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 24,
        clientName: "Name McName",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 8,
        type: "provider",
        typeName: "Salones de Chihuahua3",
        serviceStar: 4,
        qualityStar: 5,
        overallStar: 4,
        recomendationAverage: 9,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
      {
        id: 25,
        clientName: "Another name",
        evaluationDate: "00/00/0000",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        rating: 9,
        type: "provider",
        typeName: "Salones de Chihuahua4",
        serviceStar: 5,
        qualityStar: 4,
        overallStar: 4,
        recomendationAverage: 10,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
        clientFeedback:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      },
    ],
  }),

  computed: {
    loadedEvaluations() {
      if (this.filterResult === 0) {
        return this.allEvaluations.slice(0, this.evaluationShow);
      } else if (this.filterResult === 1) {
        return this.clientEvaluations.slice(0, this.evaluationShow);
      } else {
        return this.providerEvaluations.slice(0, this.evaluationShow);
      }
    },
  },
  methods: {
    handleShowEvaluations() {
      if (
        this.filterResult === 0 &&
        this.evaluationShow >= this.allEvaluations.length
      ) {
        this.showMoreBtn = false;
      } else if (
        this.filterResult === 1 &&
        this.evaluationShow >= this.clientEvaluations.length
      ) {
        this.showMoreBtn = false;
      } else if (
        this.filterResult === 2 &&
        this.evaluationShow >= this.providerEvaluations.length
      ) {
        this.showMoreBtn = false;
      } else {
        this.evaluationShow += 3;
      }
    },
    inputTextConsole() {
      console.log(this.nameFilter);
      this.nameFilter = "";
    },
    tabChange() {
      this.evaluationShow = 3;
      this.showMoreBtn = true;
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 255);
}
</style>
