<template>
  <v-row class="ml-md-0">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on"><u> Ver más </u></v-btn>
      </template>
      <v-card>
        <v-container class="d-flex justify-end mr-3 pb-0">
          <v-btn
            class="rounded-circle"
            text
            width="50px"
            @click="dialog = false"
            ><v-icon> mdi-window-close</v-icon></v-btn
          >
        </v-container>
        <v-container>
          <v-card-title class="justify-center py-0">
            <span class="text-h5 text-sm-h4" width="100%"
              ><b v-if="evaluationInfo.type === 'provider'"
                >Evaluación a servicio</b
              >
              <b v-else-if="evaluationInfo.type === 'client'"
                >Evaluación a cliente</b
              ></span
            >
          </v-card-title>
          <v-card-text>
            <v-container
              class="d-flex pl-0 flex-column flex-sm-row justify-space-between"
            >
              <v-container class="d-flex pl-0">
                <div>
                  <v-img
                    :width="width"
                    class="rounded-circle"
                    :src="`${evaluationInfo.clientImg}`"
                    :lazy-src="`${evaluationInfo.clientImg}`"
                  ></v-img>
                </div>
                <div class="ml-6">
                  <b>{{ evaluationInfo.clientName }}</b>
                  <br />
                  <i>{{ evaluationInfo.evaluationDate }}</i>
                  <br />
                  <b v-if="evaluationInfo.type === 'provider'"
                    >Servicio: {{ evaluationInfo.typeName }}</b
                  >
                </div>
              </v-container>
            </v-container>

            <v-container class="d-flex flex-md-row flex-column">
              <v-container>
                <h3 v-if="evaluationInfo.type === 'provider'" class="mb-5">
                  Servicio
                </h3>
                <h3 v-else-if="evaluationInfo.type === 'client'" class="mb-5">
                  Comunicación
                </h3>
                <v-rating
                  :value="evaluationInfo.serviceStar"
                  background-color="descent lighten-2"
                  color="secondary"
                  dense
                  readonly
                  length="5"
                  size="20"
                ></v-rating>
              </v-container>
              <v-container>
                <h3 v-if="evaluationInfo.type === 'provider'" class="mb-5">
                  Calidad
                </h3>
                <h3 v-else-if="evaluationInfo.type === 'client'" class="mb-5">
                  Cumplimiento
                </h3>
                <v-rating
                  :value="evaluationInfo.qualityStar"
                  background-color="descent lighten-2"
                  color="secondary"
                  dense
                  readonly
                  length="5"
                  size="20"
                ></v-rating>
              </v-container>
              <v-container>
                <h3 class="mb-5">General</h3>
                <v-rating
                  :value="evaluationInfo.overallStar"
                  background-color="descent lighten-2"
                  color="secondary"
                  dense
                  readonly
                  length="5"
                  size="20"
                ></v-rating>
              </v-container>
            </v-container>

            <v-container>
              <h2 class="mb-2">Comentarios</h2>
              <div>{{ evaluationInfo.clientMsg }}</div>
            </v-container>

            <v-container>
              <h2>¿Qué tan probable es que recomiendes Zaturna?</h2>
              <v-btn-toggle
                :value="parseInt(evaluationInfo.recomendationAverage - 1)"
                color="primary"
                class="d-flex justify-center flex-wrap mt-6"
              >
                <v-btn v-for="n in 10" :key="n" class="rounded-circle mx-3"
                  ><b class="text-h6">{{ n }}</b>
                </v-btn>
              </v-btn-toggle>
            </v-container>

            <v-container>
              <h2 class="mb-2">Comentarios</h2>
              <div>{{ evaluationInfo.clientFeedback }}</div>
            </v-container>

            <v-card-actions class="justify-center">
              <Button
                text="Cerrar"
                class="mb-5"
                aria-label="Cerrar ventana de la evaluación"
                @event="dialog = false"
              />
            </v-card-actions>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "ModalEvaluation",
  components: {
    Button,
  },
  props: {
    evaluationInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    dialog: false,
    width: 63,
    toggle: 9,
  }),
  methods: {},
};
</script>
